import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Flex, Input, InputRef, Tag, theme, Tooltip } from 'antd';
import {
  createNetworkLocationSource,
  deleteNetworkLocationSource,
  getNetworkLocationSources,
  GetNetworkLocationSourcesResponse,
  updateNetworkLocationSource,
  useFetch
} from 'api';
import { DarkModeContext } from 'layouts/app_layout';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CSSProperties } from 'styled-components';

const tagInputStyle: CSSProperties = {
  height: 22,
  marginInlineEnd: 8,
  verticalAlign: 'top',
  width: 64
};

const NetworkLocationSources = ({ networkId }: { networkId: string }) => {
  const isDarkMode = useContext(DarkModeContext);
  const { data } = useFetch(getNetworkLocationSources, ['1', '50', { network_id: networkId }]);

  const { token } = theme.useToken();
  const [tags, setTags] = useState<GetNetworkLocationSourcesResponse[]>(data?.data ?? []);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    setTags(data?.data ?? []);
  }, [data]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleClose = async (removedTag: GetNetworkLocationSourcesResponse) => {
    try {
      await deleteNetworkLocationSource(networkId, removedTag.id);
      const newTags = tags.filter(tag => tag.id !== removedTag.id);
      setTags(newTags);
      toast.success(`${removedTag.name} removed from location sources.`);
    } catch (error) {
      toast.error(`Failed to remove ${removedTag.name} from location sources. ${error}`);
    }
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = async () => {
    if (inputValue && !tags.map(tag => tag.name).includes(inputValue)) {
      try {
        const { data } = await createNetworkLocationSource(networkId, {
          network_location_source: { name: inputValue }
        });
        setTags([...tags, data.data]);
        toast.success(`${inputValue} added as location source.`);
      } catch (error) {
        toast.error(`Failed to add ${inputValue} from location sources. ${error}`);
      }
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = async () => {
    const originalName = tags[editInputIndex].name;
    if (originalName === editInputValue) {
      // Name is unchanged
      setEditInputIndex(-1);
      setEditInputValue('');
      return;
    }

    // Name is changed
    try {
      await updateNetworkLocationSource(networkId, tags[editInputIndex].id, {
        network_location_source: { name: editInputValue }
      });
      const newTags = [...tags];
      newTags[editInputIndex].name = editInputValue;
      setTags(newTags);
      toast.success(`${originalName} changed to ${editInputValue}.`);
    } catch (error) {
      toast.error(`Failed to change name to ${editInputValue}. ${error}`);
    }
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  const tagPlusStyle: CSSProperties = {
    background: token.colorBgContainer,
    borderStyle: 'dashed',
    height: 22
  };

  return (
    <Flex
      gap="4px 0"
      wrap
    >
      {tags.map<ReactNode>((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              key={tag.id}
              ref={editInputRef}
              onBlur={handleEditInputConfirm}
              onChange={handleEditInputChange}
              onPressEnter={handleEditInputConfirm}
              size="small"
              style={tagInputStyle}
              value={editInputValue}
            />
          );
        }
        const isLongTag = tag.name.length > 20;
        const tagElem = (
          <Tag
            key={tag.id}
            closable
            closeIcon={<CloseCircleFilled style={{ color: isDarkMode ? '#3c89e8' : '#0958d9' }} />}
            color="blue"
            onClose={() => handleClose(tag)}
            style={{ userSelect: 'none' }}
          >
            <span
              onDoubleClick={e => {
                setEditInputIndex(index);
                setEditInputValue(tag.name);
                e.preventDefault();
              }}
            >
              {isLongTag ? `${tag.name.slice(0, 20)}...` : tag.name}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip
            key={tag.id}
            title={tag.name}
          >
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible ? (
        <Input
          ref={inputRef}
          onBlur={handleInputConfirm}
          onChange={handleInputChange}
          onPressEnter={handleInputConfirm}
          size="small"
          style={tagInputStyle}
          type="text"
          value={inputValue}
        />
      ) : (
        <Tag
          color="blue"
          icon={<PlusOutlined style={{ color: isDarkMode ? '#3c89e8' : '#0958d9' }} />}
          onClick={showInput}
          style={tagPlusStyle}
        >
          New source
        </Tag>
      )}
      <span
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: '10px',
          fontStyle: 'italic',
          justifyContent: 'center'
        }}
      >
        Double-click a tag name to edit.
      </span>
    </Flex>
  );
};

export default NetworkLocationSources;
