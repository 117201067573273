/* eslint-disable jsx-a11y/anchor-is-valid */
import { BookOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Popconfirm, Table, Tag } from 'antd';
import { deleteNetwork, getBookmarks, getNetwork, GetNetworkResponse, useFetch } from 'api';
import {
  deleteNetworkMember,
  getNetworkMembers,
  GetNetworkMembersResponse
} from 'api/network_members';
import { bookmarksColumns } from 'components/bookmark_columns';
import { CardHeader } from 'components/cards';
import { CardEntryList } from 'components/cards/card_entry_list';
import { DateFormatter } from 'components/date_formatter';
import { ActionsColumn, DataTable } from 'components/tables';
import { PopoverButton } from 'pages/organizations';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import {
  bookmarkNetworkAssignmentPath,
  networkEditPath,
  networksPath,
  organizationPath
} from 'utils/paths';

import AddOrganizationToNetworkModal from './add_organization_to_network_modal';
import NetworkLocationSources from './components/network_location_sources';

export const NetworkPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useFetch(getNetwork, [id]);
  const navigate = useNavigate();
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [refetchBookmarksTrigger, setRefetchBookmarksTrigger] = useState(0);
  const assignmentColumns = bookmarksColumns(setRefetchBookmarksTrigger);

  return (
    <div className="site-card-border-less-wrapper">
      <AddOrganizationToNetworkModal
        isModalOpen={isAddMemberModalOpen}
        setIsModalOpen={setIsAddMemberModalOpen}
      />
      {data?.data !== undefined && (
        <Card
          headStyle={{ fontWeight: 'bold' }}
          title={
            <CardHeader
              editPath={networkEditPath(data.data.id)}
              title={`Network | ${data.data.name}`}
            >
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'assign_educations',
                      label: (
                        <Link to={bookmarkNetworkAssignmentPath(id)}>
                          <BookOutlined /> Assign educations
                        </Link>
                      )
                    },
                    {
                      key: 'add_member',
                      label: (
                        <a onClick={() => setIsAddMemberModalOpen(true)}>
                          <PlusOutlined /> Add member
                        </a>
                      )
                    },
                    {
                      danger: true,
                      key: 'delete',
                      label: (
                        <Popconfirm
                          cancelText="Cancel"
                          okText="Yes"
                          onConfirm={() => deleteNetwork(id).then(() => navigate(networksPath))}
                          title="Are you sure you want to delete this record?"
                        >
                          <a>
                            <DeleteOutlined /> Delete
                          </a>
                        </Popconfirm>
                      )
                    }
                  ]
                }}
              >
                <Button
                  size="large"
                  style={{}}
                >
                  Actions
                </Button>
              </Dropdown>
            </CardHeader>
          }
        >
          <CardEntryList
            fieldsMap={[
              { key: 'id', title: 'ID' },
              {
                key: 'total_organization_members',
                title: 'Organization members'
              },
              {
                key: 'total_providers',
                title: 'Total providers'
              },
              {
                key: 'total_onboarded_providers',
                title: 'Total onboarded providers'
              },
              { key: 'created_at', title: 'Created at', type: 'date' },
              { key: 'updated_at', title: 'Updated at', type: 'date' },
              {
                // @ts-expect-error
                key: 'network_location_sources',
                render: (_value, record: GetNetworkResponse) => (
                  <NetworkLocationSources networkId={record.id} />
                ),
                title: 'Location sources'
              }
            ]}
            values={data.data}
          />
        </Card>
      )}
      <Card
        bordered={false}
        title="Network Managers"
      >
        <Table
          bordered
          columns={[
            {
              dataIndex: 'name',
              key: 'name',
              render: (value: GetNetworkResponse['organization_members'][0]['name'], record) => (
                <Link to={organizationPath(record.id)}>{value}</Link>
              ),
              title: 'Name'
            },
            {
              dataIndex: 'created_at',
              key: 'created_at',
              render: (value: GetNetworkResponse['organization_members'][0]['created_at']) => (
                <DateFormatter value={value} />
              ),
              title: 'Created at'
            }
          ]}
          dataSource={data?.data!.organization_managers}
          pagination={false}
        />
      </Card>
      <Card
        bordered={false}
        extra={[
          <Fragment key="add_network_member">
            <Button
              onClick={() => setIsAddMemberModalOpen(true)}
              type="primary"
            >
              <PlusOutlined /> Add network member
            </Button>
          </Fragment>
        ]}
        title="Network Members"
      >
        <DataTable
          columns={[
            {
              dataIndex: 'name',
              key: 'name',
              render: (value: GetNetworkMembersResponse['name'], record) => (
                <Link to={organizationPath(record.id)}>{value}</Link>
              ),
              title: 'Name'
            },
            {
              dataIndex: 'direct_contract',
              key: 'direct_contract',
              render: (value: GetNetworkMembersResponse['direct_contract']) => (
                <Tag color={value ? 'green' : 'blue'}>{value ? 'Contracted' : 'Inferred'}</Tag>
              ),
              title: 'Direct contract'
            },
            {
              children: [
                {
                  key: 'total',
                  render: (record: GetNetworkMembersResponse) => record.user_totals.total,
                  title: 'Total'
                },
                {
                  key: 'inferred',
                  render: (record: GetNetworkMembersResponse) => record.user_totals.inferred,
                  title: 'Inferred'
                },
                {
                  key: 'active',
                  render: (record: GetNetworkMembersResponse) => record.user_totals.active,
                  title: 'Active'
                },
                {
                  key: 'completed_onboarding',
                  render: (record: GetNetworkMembersResponse) => record.user_totals.onboarded,
                  title: 'Onboarded'
                }
              ],
              dataIndex: 'user_totals',
              key: 'user_totals',
              title: 'Users'
            },
            {
              dataIndex: 'created_at',
              key: 'created_at',
              render: (value: GetNetworkMembersResponse['created_at']) => (
                <DateFormatter value={value} />
              ),
              title: 'Created at'
            },
            {
              key: 'actions',
              render: (record: GetNetworkMembersResponse) => {
                const removeFromOrganization = async () => {
                  await deleteNetworkMember(id, record.id);
                  window.location.reload();
                };

                return (
                  <ActionsColumn
                    customPaths={{
                      remove: (
                        <Popconfirm
                          cancelText="No"
                          okText="Yes"
                          onConfirm={removeFromOrganization}
                          title="Are you sure you want to remove this organization from this network?"
                        >
                          <PopoverButton>Remove from network</PopoverButton>
                        </Popconfirm>
                      )
                    }}
                    record={record}
                  />
                );
              },
              title: 'Actions',
              width: '60px'
            }
          ]}
          filters={[
            {
              key: 'direct_contract',
              label: 'Direct contract',
              options: [
                { text: 'Contracted', value: 'true' },
                { text: 'Inferred', value: 'false' }
              ]
            },
            {
              key: 'member_name',
              label: 'Name'
            }
          ]}
          getMethod={getNetworkMembers}
          getParams={{ network_id: id }}
        />
      </Card>
      <Card
        bordered={false}
        title="Assigned Education"
      >
        <DataTable
          columns={assignmentColumns}
          getMethod={getBookmarks}
          getParams={{
            assigned_by_id: id,
            assigned_by_type: 'Network'
          }}
          refetchTrigger={refetchBookmarksTrigger}
        />
      </Card>
    </div>
  );
};
